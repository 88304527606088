.loginLayout {
  height: 100vh;
  background-color: #f7f7fc;
}

.logoImage {
  width: 100%;
}

.forgotPassword {
  text-align: center;
  margin-top: 16px;
}

.registerText {
  text-align: center;
  margin-top: 16px;
}

.registerLink {
  padding: 0 !important;
}

.forgotPasswordForm {
  margin-top: 16px !important;
}

.forgotPasswordEmail {
  margin-top: 16px !important;
}

.forgotPasswordParagraph {
  margin: 0 !important;
}
