.leftSide {
  text-align: left;
}

.text {
  color: #06957e !important;
}

.centerSide {
  text-align: center;
}

.rightSide {
  text-align: right;
}
