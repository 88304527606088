.leadItem {
  background-color: white;
  border-radius: 5px;
  margin-bottom:8px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.1);
}

.actionLink {
  margin-right: 8px;
}

.skeletonListItem {
  margin-left: 16px;
}
