.bar {
  height: 64px;
  background-color: #16ba8d;
  border-radius: 30px;
  border: 2px solid #ffffff;
  margin-top: 16px;
  width: 100%;
}

.leftSide {
  display: flex !important;
  justify-content: center;
  text-align: center;
}

.centerSideofSkeleton {
  display: flex;
  justify-content: center;
  text-align: center;
}

:global .ant-skeleton-content {
  display: flex !important;
  justify-content: center !important;
  align-items: baseline !important;
}

.rightSide {
  display: flex !important;
  justify-content: flex-end;
  text-align: center;
}

.rightSide .marginRight {
  margin-right: 16px;
}

.percentageBar {
  background-color: #16baa2;
  border-radius: 30px;
  width: 60%;
}

.percentageText {
  color: #ffffff !important;
}

.centerSide {
  text-align: center;
}

.centeredText {
  text-transform: capitalize;
  color: #ffffff !important;
}

.count {
  background-color: #16baa2;
  border-radius: 50px;
  width: 45px;
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.countText {
  color: #ffffff !important;
}
