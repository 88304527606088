.mobileAlert {
  display: none;
  z-index: 99999;
  position: absolute;
  min-height: 100vh;
  height:100%;
  background-color :#f6f8f9;
  color: #444;
  width:100%;
  text-align: center;
}

.globalLayout {
  display: flex;
}

@media (min-width: 992px) {
  .menu {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  .mobileAlert {
    display: block !important;
  }
  .globalLayout {
    display: none !important;
  }
}

.mobileContainer {
  background-color: #fff;
  max-width: 100%;
  padding-bottom: 0;
}

.mobileBottomContainer {
  background: #f6f8f9;
  text-align: center;
}

.redirectText {
  color: #273240;
  font-size: 24px;
  font-weight: 500;
  margin: 48px auto 8px;
}

.redirectSubtext {
  color: #6f7782;
  font-size: 16px;
  margin: 8px auto 8px;
}

.redirectBadgeIos {
  display: block;
  margin: 24px auto 24px auto;
  width: 140px;
}
.redirectBadgeAndroid {
  display: block;
  margin: 24px auto 24px auto;
  width: 165px;
}